export default {
  "header": {
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шри-Ланка"])},
    "China": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Китай"])},
    "Laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаос"])},
    "Turkiye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Турция"])}
  },
  "footer": {
    "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])},
    "Social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Социальное"])},
    "ICanMeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan кошелек"])},
    "ICanChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу общаться в чате"])},
    "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свойства ICan"])},
    "ServiceAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисное соглашение"])},
    "PrivacyAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соглашение о конфиденциальности"])},
    "QRPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат и возврат"])},
    "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощь и поддержка"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с нами"])},
    "SocilaMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставайся на связи"])},
    "SaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите приложение"])}
  },
  "message": {
    "LoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешный вход пользователя"])},
    "LoginToMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти в iCan Mall"])},
    "SomethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так"])},
    "DataDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия чека истек!"])},
    "AddressAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес успешно добавлен"])},
    "DeleteAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить адрес успешно"])},
    "UpdateAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес успешно изменен"])},
    "ChangeAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес доставки изменен"])},
    "CodeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код отправлен"])},
    "ResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить успех"])},
    "AddFavourits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлено в избранное"])},
    "ItemOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет в наличии"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлено в корзину"])},
    "LoggedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вышли из"])},
    "PasswordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно обновлен!"])},
    "ProfileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль успешно обновлен!"])},
    "OrderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ отменен"])},
    "InvalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный номер мобильного телефона"])},
    "RegisterSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация прошла успешно"])},
    "NoFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без фильтров"])},
    "FreeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатная доставка по вашему региону"])},
    "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, здесь нет отзывов!"])},
    "prodnotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот продукт недоступен в вашем регионе"])},
    "AddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пожалуйста, добавьте свой адрес"])},
    "DelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить заказ успешно"])},
    "HaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас уже есть аккаунт?"])},
    "CODPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете оплатить наличными или банковской картой нашему курьеру при получении товара на адрес доставки."])},
    "cancelledOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ отменен успешно"])},
    "onlyfiveImgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете загрузить только пять изображений."])},
    "EnterYourThought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пожалуйста, введите свои мысли"])},
    "CommentsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши комментарии успешно добавлены"])},
    "NoCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этой категории нет ничего, что можно было бы показать!"])},
    "ConfirmByCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как только вы подтвердите, оплата будет продолжена с выбранной карты."])},
    "NoVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этой категории нет ничего, что можно было бы показать!"])},
    "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успех"])},
    "FormValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упс! Похоже, вы пропустили несколько полей или ввели неверные данные."])},
    "PageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Page Not Found"])},
    "PageNotFoundDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the page you're looking for cannot be found."])},
    "GoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Home"])},
    "minPayAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма оплаты не должна быть меньше"])},
    "alreadyDefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected address is already your default address"])}
  },
  "headerTitle": {
    "ICanMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCanMall"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация Аккаунта"])},
    "ChangePwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["товары"])},
    "IcanServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги iCan"])},
    "TrendingDeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные предложения"])},
    "YouMayLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам может понравиться"])},
    "MyCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избранное"])},
    "Myfootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой след"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "DefaultAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить адрес по умолчанию"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "AccountSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт и безопасность"])},
    "PersonalProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный профиль"])},
    "PasswordSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность пароля"])},
    "EditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать личный профиль"])},
    "PwdChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
    "ProdDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание продукта"])},
    "ProdSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["характеристики продукта"])},
    "CusReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзывы клиентов"])},
    "AbtSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О продавце"])},
    "SimilarProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похожие продукты"])},
    "AllReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все отзывы"])},
    "DeliveryAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес доставки"])},
    "OrderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["итог заказа"])},
    "TotAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма"])},
    "PaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способы оплаты"])},
    "Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краткое содержание"])},
    "Addr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
    "EditAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить адрес"])},
    "AddNewAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новый адрес"])},
    "ShoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша Корзина"])},
    "CartEmplty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша корзина пуста"])},
    "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуется для вас"])},
    "ToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платить"])},
    "ToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставлять"])},
    "ToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершенный"])},
    "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возвращать деньги"])},
    "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальность"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
    "Popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярный"])},
    "searchResultFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты поиска для"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты поиска"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])},
    "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукты"])},
    "Shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазины"])},
    "CoinPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена монеты"])},
    "CoinRrecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монетные пластинки"])},
    "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление"])},
    "Invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мое приглашение"])},
    "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
    "Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренды"])},
    "COD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить с помощью наложенного платежа?"])},
    "OrderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ размещен"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курьерская служба"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификационный номер"])},
    "OrederCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить заказ"])},
    "RateProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценить продукт"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
    "Mall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Lanka PVT Ltd."])},
    "App": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы подтверждаете получение?"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои ваучеры"])},
    "Trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самые популярные предложения сегодняшнего дня"])},
    "AppAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение iCan Mall уже доступно"])},
    "confirmedOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить и оплатить"])},
    "SelectPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите метод оплаты"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненные карты"])}
  },
  "subTitles": {
    "DefaultAddrSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, предоставьте дополнительную информацию"])},
    "IcanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить и оплатить Я подтверждаю, что данные моей карты сохраняются в хранилище банковских карт для последующих транзакций. При необходимости карту можно удалить в папке \"Payment Options\" section in the \"Account\" menu."])},
    "LoginEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти по электронной почте"])},
    "LoginMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через мобильный"])},
    "Forgotpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
    "CreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите свои данные, чтобы создать учетную запись"])},
    "RegisterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрируйтесь через электронную почту"])},
    "RegisterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрируйтесь с мобильного"])},
    "PleaseEnterMobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите зарегистрированный номер мобильного телефона"])},
    "PleaceEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите зарегистрированный адрес электронной почты"])},
    "ResetWithMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс с помощью мобильного телефона"])},
    "ResetWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить по электронной почте"])},
    "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
    "Item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unid"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "Additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительное описание и учетные данные"])},
    "CancelledDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности отмены"])},
    "RateProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцените свой продукт"])}
  },
  "placeholeds": {
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистр"])},
    "ShopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить сейчас"])},
    "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменять"])},
    "AddNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новое"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "SaveConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить и подтвердить"])},
    "PlaceOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разместить заказ"])},
    "SeeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увидеть все"])},
    "GetCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить код"])},
    "ResetPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
    "AddCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в корзину"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить сейчас"])},
    "WriteReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Написать обзор"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуга"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфиденциальность"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезагрузить"])},
    "SaveCanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
    "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить"])},
    "SelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "DeleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все"])},
    "ContinueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить покупки"])},
    "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
    "ServiceAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисное соглашение"])},
    "PrivacyAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соглашение о конфиденциальности"])},
    "ContactSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с продавцом"])},
    "ViewLogistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть логистику"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить получение"])},
    "DeleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить заказ"])},
    "Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценить продукт"])},
    "CancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить заказ"])},
    "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
    "ContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контактное лицо"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провинция"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
    "AddrL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адресная строка"])},
    "Zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])},
    "SearchProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск продуктов"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посетить магазин"])},
    "ConfirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать"])},
    "ViewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть заказ"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Представлять на рассмотрение"])},
    "SubmitReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить отзыв"])},
    "AccoutAndSecutiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учетная запись и безопасность"])},
    "Collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собирать"])},
    "DownloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите приложение iCan Mall"])},
    "confirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите заказ"])},
    "continuePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платить"])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка..."])},
    "LoadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузи больше"])},
    "PasswdLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль Войти"])},
    "QuickLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрый вход"])},
    "ShippinFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатная доставка"])},
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По умолчанию"])}
  },
  "formLabels": {
    "PersonalInfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональная информация"])},
    "CountryRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна/регион"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
    "MobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер мобильного телефона"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "NewPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
    "ConfirmPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите пароль"])},
    "VerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверочный код"])},
    "DefaultAddrlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить как адрес по умолчанию"])},
    "Nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Псевдоним"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пол"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День рождения"])},
    "Currentpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])},
    "InviteCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код приглашения"])},
    "IAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["я согласен"])},
    "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмененная сумма"])},
    "CancelReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите причину отмены"])},
    "AddRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте свои мысли"])},
    "AddImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить изображения"])},
    "isAnonymus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцените продукт как анонимный"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрачный"])},
    "MobileOrPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер мобильного телефона или адрес электронной почты"])}
  },
  "Label": {
    "Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРОДАЛ"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВАУЧЕРЫ"])},
    "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "DeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценить дату доставки"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
    "5Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 звезд"])},
    "4Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 звезды"])},
    "3Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 звезды"])},
    "2Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 звезды"])},
    "1Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 звезда"])},
    "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промежуточный итог"])},
    "BasketCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["корзины в тележке"])},
    "ItemCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код изделия"])},
    "TotalItemCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая стоимость товара"])},
    "SavedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранено ваучерами"])},
    "PromoCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промокоды"])},
    "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата за доставку"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить данные карты"])},
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатно"])},
    "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
    "OrderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер заказа"])},
    "RefundAmmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма возмещения"])},
    "Cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата при доставке"])},
    "InStok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В наличии"])},
    "OutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распродано"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканируй меня"])},
    "AppDisriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующая революция в электронной коммерции с удивительными"])},
    "AppDisriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["предложения и невероятные цены"])},
    "CopyRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все права защищены."])},
    "DefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес доставки по умолчанию"])},
    "NoAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес не указан"])},
    "VocherCampain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать в торговый центр Ican! Получите эксклюзивные купоны для новых пользователей прямо сейчас!🎉"])},
    "DownloadDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очередная революция в электронной коммерции с потрясающими предложениями и невероятными ценами."])},
    "UseAppDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте приложение iCan Mall в любое время и в любом месте, чтобы находить товары, связываться с поставщиками, управлять заказами и совершать платежи."])},
    "GetApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить приложение"])},
    "subTot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промежуточный итог"])},
    "ShipFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевозки"])},
    "Cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитная / дебетовая карта"])},
    "Deleverytime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок поставки"])},
    "DeliveryService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служба доставки"])},
    "EstimatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предполагаемая дата поставки"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать"])},
    "TimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временной интервал"])},
    "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланировать доставку"])},
    "Availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "Ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "Courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])},
    "DeliveryTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery To"])},
    "DeliveryDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date"])},
    "EstimateDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate delivery"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "Warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty"])},
    "YearWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Seller Warranty"])},
    "WarrantyNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty Not Available"])},
    "ShipFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship From"])},
    "Overseas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overseas"])},
    "Voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit store"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Cart"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now"])},
    "ProductSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Specifications"])},
    "ChatNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Now"])},
    "SoldOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOLD OUT"])}
  },
  "ValidationMsg": {
    "FieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это поле обязательно к заполнению"])},
    "SelectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите код страны"])},
    "ValidNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный номер мобильного телефона"])},
    "EnterPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста введите пароль"])},
    "PWRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не может содержать начальные или конечные пробелы"])},
    "OTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите код подтверждения"])},
    "ValiedCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный код"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите пароль еще раз"])},
    "PwNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не совпадает"])},
    "EnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите адрес электронной почты"])},
    "ValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный адрес электронной почты"])},
    "MobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите номер мобильного телефона"])},
    "ValidNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите никнейм"])},
    "PwTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен состоять из 8 символов и содержать хотя бы одну заглавную букву, простую букву, символ и цифру."])},
    "AddrTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество символов не может превышать 500."])}
  },
  "NotItemsMessage": {
    "notItemcart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в корзине нет предметов"])},
    "notItemTopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет товаров для оплаты"])},
    "notNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет никаких уведомлений"])},
    "notItemToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет товаров для доставки"])},
    "notSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извини. Мы не можем найти совпадений по вашему поисковому запросу"])},
    "notFavourit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете добавить товар в избранное, нажав на значок сердечка."])},
    "notItemToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет товаров для получения"])},
    "notCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разделе Завершено нет ни одного товара"])},
    "notFootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["След пуст!"])},
    "notItemRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет товаров для возврата"])},
    "notReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, отзывов об этом товаре пока нет"])}
  },
  "CancelOrderReasons": {
    "Duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать заказ"])},
    "WrongProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильное описание продукта"])},
    "ChangeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменен адрес доставки"])},
    "ChangeMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["я передумал"])},
    "FoundCheaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нашел дешевле в другом месте"])},
    "deliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок доставки слишком длинный"])}
  },
  "invitationInfo": {
    "UnlockRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ScantoRefer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсканируйте, чтобы порекомендовать другу!🎉"])},
    "MyInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мое приглашение"])},
    "Invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашен"])},
    "YourRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш ранг"])},
    "confInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвержденное приглашение"])},
    "Leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблица лидеров"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Классифицировать"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "Refferel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рефф"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конф"])}
  },
  "voucherInfo": {
    "CappedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничено на"])},
    "PercentageDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% ВЫКЛЮЧЕННЫЙ"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherValidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применять"])},
    "VoucherApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применяемый"])},
    "VoucherCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собирать"])},
    "VoucherCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собрано"])},
    "VoucherCollectedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherCollectError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "AllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "ProductVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])},
    "ShopVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
    "MallVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Молл"])},
    "MyVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "RecommendedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рекомендуемые"])},
    "ViewAllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["более"])},
    "ShopVoucherTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купон и скидка"])}
  },
  "campain": {
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дни"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часы"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минуты"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секунды"])}
  },
  "GroupBuy": {
    "groupBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групповая покупка"])},
    "NeedParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нуждаться"])},
    "NoParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "timeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тайм-аут"])},
    "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["день"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["час"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мин"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сек"])},
    "InPeogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ходе выполнения"])},
    "Upcomming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершенный"])}
  },
  "AddressMobile": {
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернатива"])},
    "enterAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите альтернативный номер"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Область"])},
    "selectArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите область, район, город"])},
    "yourArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите свой регион"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбирать"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["близко"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["province"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["district"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["town"])}
  },
  "Languages": {
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
    "Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Китайский"])},
    "Turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["турецкий"])},
    "Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
    "Portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Португалия"])}
  },
  "BannerText": {
    "BannerBottomHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock Your Shopping Adventure!"])},
    "BannerBottomSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download our app now to discover electronics, fashion experiences, amazing offers, and more!"])},
    "BannerBottomSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the Qr code to download iCan Mall"])}
  },
  "OrderDetails": {
    "OrderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier service"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
    "Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "OrderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
    "Copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "PlacedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placed on"])},
    "PaidOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid on"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery insurance"])},
    "ShopDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop discount"])},
    "SeeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
    "SeeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See less"])},
    "SubmitOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit order"])},
    "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
    "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
    "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copied"])}
  },
  "ContactUs": {
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "SubHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real time and fast responses"])},
    "NeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
    "ContactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can contact iCan mall Customer service for help."])},
    "OfficeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office Address"])},
    "SocialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are also available on social media"])}
  }
}