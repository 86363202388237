import axios from "@/libraries/Axios";

// GET REGIONS IN HEADER
export const getRegionList = () => axios.get("/salesRegions/all")

//GET SERVICE LIST (TAG LIST)
export const getServiceList = (regionId) => axios.get("/indexTag/taglist?regionId="+regionId)

//GET CATEGORY LIST
export const getCategoryList = (regionId) => axios.get("/indexTag/list?regionId="+regionId)

//GET TRENDING PRODUCT
export const getTrendingProducts = (regionId) => axios.get("/prod/tagProdList?regionId="+regionId)

//GET CATEGORIES
export const getSpecialityCategories = () => axios.get("/category/categoryInfo")

//GET RANDOM PRODUCTS
export const getRandomProducts = (current,size,regionId) => axios.get("/indexTag/moreGoodStuff?current="+current+"&size="+size+"&regionId="+regionId)

//GET PRODUCTS BY TAGLIST
export const getTagListProducts = (tagId,regionId) => axios.get("/indexTag/tagdetaillist?tagId="+tagId+"&regionId="+regionId)

//GET SIMILAR PRODUCT
export const getSimilarProducts = (prodId,current, regionId) => axios.get("/prod/similar?prodId="+prodId+"&current="+current+"&size=12"+"&regionId="+regionId)

//HOME PAGE ITEMS
export const getHomeProducts = (current,size,regionId) => axios.get("/indexTag/moreGoodStuff?current="+current+"&size="+size+"&regionId="+regionId)

// GET BANNER IMAGES
export const getBannerImages = (regionId) => axios.get("/indexImgs?regionId="+regionId)