import axios from "@/libraries/Axios";

// export const searchByFilter = ({categoryTypeAttrIds,key,asc,regionId}) => axios.get("/search?categoryTypeAttrIds="+categoryTypeAttrIds+"&key="+key+"&scrollId=&pageSize="+1+"&pageNumber="+10+"&sort="+asc+"&sortName=&oldSort="+0+"&placeOfOrigin=&regionId="+regionId)
export const searchByFilter = ({ categoryTypeAttrIds, key, regionId }) => axios.get("/search?categoryTypeAttrIds=" + categoryTypeAttrIds + "&key=" + key +"&scrollId=&pageSize=1&pageNumber=10&sort=asc&sortName=&oldSort=0&placeOfOrigin=&regionId="+regionId)
export const search = ({key,asc,regionId,pageSize,pageNumber,scrollId}) => axios.get("/search?categoryTypeAttrIds=&key="+key+"&scrollId="+scrollId+"&pageSize="+pageSize+"&pageNumber="+pageNumber+"&sort="+asc+"&sortName=&oldSort="+0+"&placeOfOrigin=&regionId="+regionId) 
export const popularSearches = () => axios.get("/search/hotSearch?number=10&sort=1")

// SHOP TAB SEARCH
export const searchShops = (name) => axios.get(`shopDetail/byShopName?name=${name}`)

// GET keyword suggestion
export const getSuggestions = (keyword, size) => axios.get('/search/suggest/keyword?keyword='+keyword+"&size="+size)
